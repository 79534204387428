<div class="container-fluid">
    <app-menu></app-menu>
</div>
<div class="container pt-4 privacy-advice">
    <app-aviso-privacidad></app-aviso-privacidad>
    <div class="row mt-0 mb-3">
        <div class="col-12 col-md-4 options">
            <button class="btn btn-exit" (click)="onClose()" animateTag animateType="bounce">
                Cerrar
              </button>
        </div>
    </div>
</div>