<div class="row pt-5 justify-content-between options">
    <div class="col-3">
        <fa-icon [icon]="faHome" class="icon-home" animateTag animateType="bounce" (click)="onRedirectHome()"></fa-icon>
    </div>
    <div class="col-3 text-right div-menu">

        <fa-icon [icon]="faBars" class="icon-bars" animateTag animateType="bounce" (click)="show=!show"></fa-icon>
        <div class="div-options" *ngIf="show">
            <a class="dropdown-item" href="/que-es-cuantrix">Acerca de</a>
            <a class="dropdown-item" href="/ayuda">Ayuda</a>
            <a class="dropdown-item" href="/contacto">Contacto</a>
        </div>
    </div>
</div>