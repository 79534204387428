import { Component, OnInit } from '@angular/core';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';


@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.scss']
})
export class AyudaComponent implements OnInit {
  faArrow = faPaperPlane;
  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  // redirect to the previous page
  back = (): void => this.location.back();

}
