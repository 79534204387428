<div class="what-cuantrix">
    <div class="container-fluid">
        <app-menu></app-menu>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 box marginTop mb-5">
                <div class="row">
                    <div class="col-12 col-close">
                        <button class="btn btn-close" routerLink="/inicio">X</button>
                    </div>
                    <div class="col-12 text-center marginTop mb-4">
                        <img src="../../../../assets/img/qec.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-12 d-md-flex justify-content-center text-center">
                        <span class="title">¿Qué es</span>
                        <img class="pl-2 pr-2 logo" src="../../../../assets/img/logo2.png" alt="">
                        <span class="title">?</span>
                    </div>
                </div>

                <div class="row imgs mb-4 justify-content-center">
                    <div class="col-10 text-center">
                        <p class="subtitle mt-1">
                            Cuantrix es un programa de izzi, AMITI y Fundación Televisa que busca generar equidad de oportunidades a través de las ciencias de la computación para que, cada año en México, un millón de niñas, niños y jóvenes aprendan a programar.
                        </p>
                        <p class="subtitle mt-1">
                            En Cuantrix brindamos herramientas metodológicas, recursos audiovisuales y actividades prácticas para que padres y maestros puedan guiar a los niños en su aprendizaje de manera sencilla y divertida, ya sea en casa o en la escuela.
                        </p>
                    </div>
                </div>

                <!-- <div class="row ">
                    <div class="col-3 offset-5">
                        <button class="btn btn-help" routerLink="/ayuda" animateTag animateType="bounce">
              Ayuda
            </button>
                    </div>
                    <div class="col-3 text-right">
                        <button class="btn btn-next" routerLink="/padres/porque-registro-hijo" animateTag animateType="bounce">
              Siguiente
            </button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>