<div class="contacto">
    <div class="container-fluid">
        <app-menu></app-menu>
    </div>
    <div class="container">
        <div class="row mt-5">
            <div class="col-12 box">
                <div class="row">
                    <div class="col-12 col-close">
                        <button class="btn btn-close" routerLink="/inicio">X</button>
                    </div>
                    <div class="col-8 offset-2 d-flex justify-content-center titles-section">
                        <!-- <span class="title red">¿Necesitas ayuda?</span>
                        <span class="pl-0 pl-md-3 title">escríbenos a:</span> -->
                        <p class="title text-center">Para ponerte en contacto con nosotros, solo completa este formulario</p>
                    </div>
                </div>
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="row mt-3 p-4">
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="name">Nombre*</label>
                                    <input type="text" required class="form-control" formControlName="name" id="name" placeholder="Nombre*">
                                    <div *ngIf="submitted && formFields.name.errors" class="errors pt-1">
                                        <div *ngIf="formFields.name.errors.required">
                                            El nombre es obligatoria.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <label for="teacherOrTutor">Docente o Tutor*</label>
                                    <ng-select formControlName="teacherOrTutor" id="teacherOrTutor" class="form-control" placeholder="Seleccionar..." [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                        <ng-option value="Docente">Docente</ng-option>
                                        <ng-option value="Tutor">Tutor</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && formFields.teacherOrTutor.errors" class="errors pt-1">
                                        <div *ngIf="formFields.teacherOrTutor.errors.required">
                                            Debe seleccionar una opción de la lista.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <label for="email">Correo electrónico*</label>
                                    <input type="email" required class="form-control" id="email" formControlName="email" placeholder="Correo electrónico*">
                                    <div *ngIf="submitted && formFields.email.errors" class="errors pt-1">
                                        <div *ngIf="formFields.email.errors.required">
                                            El correo electrónico es obligatoria.
                                        </div>
                                        <small *ngIf="formFields.email.errors.pattern" class="errors pt-1">
                                            Correo inválido.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12 form-group d-none d-md-block">
                                    <button class="btn btn-exit" animateTag animateType="bounce">Enviar </button>
                                </div>

                            </div>

                        </div>
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="message">Mensaje*</label>
                                    <textarea type="text" required formControlName="message" id="" rows="9" class="form-control" placeholder="Mensaje*"></textarea>
                                    <div *ngIf="submitted && formFields.message.errors" class="errors pt-1">
                                        <div *ngIf="formFields.message.errors.required">
                                            El mensaje es obligatoria.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 form-group d-block d-md-none text-center">
                                    <button class="btn btn-exit" animateTag animateType="bounce">Enviar </button>
                                </div>
                            </div>

                        </div>

                        <!-- <div class="row justify-content-center mb-5"> -->
                        <!-- <div class="col-8 col-md-4 text-center">
                                <button class="btn btn-exit" animateTag animateType="bounce">
                                Enviar
                </button> -->
                        <!-- <button class="btn btn-exit" (click)="back()" animateTag animateType="bounce">
                                Atrás/Regresar
                </button> -->
                        <!-- </div> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>