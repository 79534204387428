<!-- Modal -->
<div class="container modal-landing">
    <div class="row dialog__content">
        <div class="col-12 p-0 col-header">
            <button class="btn btn-close" (click)="close()">X</button>
        </div>
        <div class="col-12 col-body">
            <div class="row">
                <div class="col-12 col-md-7 p-5 col-info">
                    <img class="img-fluid" src="../../../../assets/img/landing/logo-retocuantrix-color.png" alt="">
                    <button class="btn btn-blue mt-5 mb-5" disabled>Próximamente</button>
                    <p>Espera la competencia Reto Cuantrix en la que demostrarás tus habilidades en programación mientras te diviertes.</p>
                </div>
                <div class="col-12 col-md-5 col-image pb-4">
                    <img src="../../../../assets/img/landing/reto-cuantrix-modal.png" alt="">
                </div>

            </div>
        </div>
    </div>
</div>