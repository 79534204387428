import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy-advice',
  templateUrl: './privacy-advice.component.html',
  styleUrls: ['./privacy-advice.component.scss']
})
export class PrivacyAdviceComponent implements OnInit {
  constructor(private router: Router ) { }

  ngOnInit(): void {
    
  }
  onClose() {
    window.close();
  }
}
