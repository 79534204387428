import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SafePipe } from 'src/app/_helpers/safe.pipe';

@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss']
})
export class InformacionComponent implements OnInit {
  // VideoUrl
  videoUrl;
  // Title text;
  titleText: string = 'Bienvenid@ a Cuantrix';
  constructor(
    private dialogRef: MatDialogRef<InformacionComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.videoUrl);
  }

  // Close dialog
  close(){
    this.dialogRef.close();
  }

}
