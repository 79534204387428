<section class="aviso-privacidad">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 register__box margin--top">
        <div class="row register__header">
          <div class="col-12 text-center text-md-left mt-2">
            <div class="register__title">Aviso de privacidad</div>
          </div>
        </div>
        <div class="row register__content pt-4">
          <div class="col-12">
            <div class="content">
              <p>
                <strong> RESPONSABLE DE LOS DATOS PERSONALES. </strong>
              </p>
              <p>
                Fundación Televisa, A.C. (en adelante "Fundación") con domicilio
                en Avenida Vasco de Quiroga No. 2000, Colonia Santa Fe, Alcaldía
                Álvaro Obregón, Ciudad de México, C.P. 01210 tiene la convicción
                de proteger la información personal proporcionada por los
                usuarios (en adelante "Datos Personales”) que se registren a la
                “Plataforma Cuantrix Niños” (en adelante la “Plataforma”) y es
                el responsable de su tratamiento (término que se define más
                adelante) cuando sean recabados a través de la Plataforma.
              </p>
              <p>
                <strong>
                  FINALIDAD DEL TRATAMIENTO DE DATOS PERSONALES.
                </strong>
              </p>
              <p>
                Fundación podrá solicitar y/o recabar a través de la “Plataforma
                Cuantrix Niños” (en adelante la “Plataforma”) Datos Personales
                de los usuarios para los fines abajo señalados, así como para
                dar cumplimiento con disposiciones legales que así lo requieran
                (en adelante “Tratamiento”). Fundación y/o cualquier tercero que
                llegue a intervenir en cualquier fase del Tratamiento de Datos
                Personales guardará confidencialidad respecto de los mismos
                conforme a las disposiciones legales aplicables en los Estados
                Unidos Mexicanos (en adelante “México”).
              </p>

              <p>
                Los Datos Personales que los usuarios proporcionen, tienen como
                finalidad; i) se registren los usuarios a la Plataforma ii) los
                usuarios puedan acceder a la plataforma y (iii) para que los
                maestros puedan llevar acabo las lecciones educativas (ciencias
                de la programación).
              </p>

              <p>
                <strong>
                  DATOS PERSONALES A RECABAR.
                </strong>
              </p>

              <p>
                Los Datos Personales de los usuarios y/o maestros son:
              </p>
              <ul>
                <li>Nombre completo</li>
                <li>Clave del centro de trabajo</li>
                <li>Curp</li>
                <li>Teléfono</li>
                <li>Correo electrónico</li>
                <li>Contraseña</li>
                <li>Género</li>
                <li>Nombre de la escuela en la que labora</li>
                <li>Grado en el que imparte clases</li>
              </ul>

              <p>
                <strong>
                  PRIVACIDAD Y CONSENTIMIENTO.
                </strong>
              </p>

              <p>
                Para el caso de mayores de edad, el llenado del formato, implica
                el consentimiento pleno y sin reservas para el Tratamiento de
                sus Datos Personales de acuerdo con el presente Aviso de
                Privacidad.
              </p>

              <p>
                El titular de los Datos Personales manifiesta que es mayor de
                edad a la fecha en que se proporcionan los mismos y cuenta con
                plena capacidad jurídica. Cuando se traten datos de menores de
                edad o de personas que se encuentren en estado de interdicción o
                incapacidad establecida por ley, no se recabarán Datos
                Personales de dichos titulares, sin contar con el consentimiento
                previo de la persona que ejerce la patria potestad, o en su
                caso, del tutor o representante legal, de conformidad con las
                reglas de representación aplicables en cada caso y conforme al
                apartado siguiente.
              </p>

              <p>
                <strong>
                  MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.
                </strong>
              </p>

              <p>
                Medios para limitar el uso o divulgación de datos personales.
              </p>

              <p>
                Fundación Televisa cuenta con medidas físicas, técnicas y
                administrativas de seguridad para la protección de Datos
                Personales proporcionados por los usuarios. No obstante lo
                señalado en el apartado de Consentimiento, Fundación Televisa
                tomará las acciones, medidas y previsiones especiales que
                considere necesarias, a fin de salvaguardar el derecho a la
                protección de datos personales de menores de edad e incapaces.
              </p>

              <p>
                <strong>
                  TRANSFERENCIA DE DATOS PERSONALES.
                </strong>
              </p>

              <p>
                Fundación podrá, sin consentimiento del titular, revelar,
                divulgar y/o transferir dentro y fuera del país los Datos
                Personales proporcionados por los usuarios, a sus empresas
                filiales, subsidiarias y/o relacionadas, así como para dar
                cumplimiento con disposiciones legales que así lo requieran, o
                bien cuando sea solicitado por autoridades competentes.
              </p>

              <p>
                <strong>
                  MEDIOS PARA EJERCER LOS DERECHOS DE LOS TITULARES DE DATOS
                  PERSONALES Y REVOCACIÓN DEL CONSENTIMIENTO.
                </strong>
              </p>

              <p>
                Los usuarios podrán solicitar el ejercicio de sus derechos a:
              </p>

              <ul>
                <li>Acceder a sus Datos Personales;</li>
                <li>
                  Rectificar sus Datos Personales cuando sean inexactos o
                  incompletos;
                </li>
                <li>Cancelar sus Datos Personales;</li>
                <li>
                  Oponerse por causa legítima al Tratamiento de sus Datos
                  Personales, y/o
                </li>
                <li>
                  Revocar su consentimiento para el Tratamiento de sus Datos
                  Personales en cualquier momento, a fin de que se deje de hacer
                  uso de los mismos.
                </li>
              </ul>

              <p>
                Sin embargo, es importante que tenga en cuenta que no en todos
                los casos podremos atender su solicitud para cancelar sus Datos
                Personales, oponerse al Tratamiento o revocar su consentimiento,
                ya que es posible que por alguna obligación legal requiramos
                seguir tratando sus Datos Personales.
              </p>

              <p>
                El ejercicio de los derechos de Acceso, Rectificación,
                Cancelación u Oposición de Datos Personales y Revocación del
                consentimiento que realicen los usuarios deberá solicitarse a
                Fundación y contener al menos lo siguiente:(______________)
              </p>

              <ul>
                <li>
                  El nombre del titular, domicilio y correo electrónico para
                  comunicarle la respuesta a su solicitud
                </li>
                <li>
                  Los documentos que acrediten la identidad o, en su caso, la
                  representación legal del titular;
                </li>
                <li>
                  La descripción clara y precisa de los Datos Personales
                  respecto de los que se busca ejercer alguno de los derechos
                  antes mencionados (en el caso de rectificación se deberá
                  indicar las modificaciones a realizar y aportar la
                  documentación que sustente su petición);
                </li>
                <li>
                  Cualquier otro elemento o documento que facilite la
                  localización de los Datos Personales.
                </li>
              </ul>

              <p>
                Fundación dará respuesta a la solicitud de los usuarios en un
                plazo no mayor a 20 días hábiles contados a partir de la fecha
                en que reciba la petición correspondiente, a través del correo
                electrónico que haya sido proporcionado para tal efecto.
              </p>

              <p>
                El ejercicio por parte de los usuarios de los derechos antes
                mencionados deberá sujetarse a las leyes y reglamentos
                aplicables vigentes en México.
              </p>

              <p>
                <strong>
                  COOKIES Y WEB BEACONS
                </strong>
              </p>

              <p>
                Con el objetivo de mejorar la experiencia de sus usuarios en el
                Sitio, Fundación Televisa podrá utilizar “cookies”. Para efectos
                del presente Aviso de Privacidad “cookies” se identificará como
                los archivos de texto de información que un sitio web transfiere
                al disco duro de la computadora de los usuarios con el objeto de
                almacenar ciertos registros y preferencias. Fundación Televisa
                puede utilizar “cookies” para mejor entendimiento de la
                interacción de los usuarios con el Sitio y los servicios que se
                proporcionan. El Sitio puede permitir publicidad o funciones de
                terceros que envíen “cookies” a las computadoras de los
                usuarios. Mediante el uso de “cookies” no se identifica
                personalmente a los usuarios, únicamente a sus computadoras. Por
                su parte las “web beacons” son imágenes insertadas en una página
                de Internet o correo electrónico que pueden ser utilizadas para
                monitorear el comportamiento de un visitante, como almacenar
                información sobre la dirección IP del usuario, horario de
                navegación, duración del tiempo de interacción en el Sitio,
                secciones consultadas, páginas de Internet accedidas previo a la
                nuestra y el tipo de navegador utilizado. Los usuarios podrán
                cambiar sus opciones a través de sus equipos de cómputo y/o
                navegadores para dejar de aceptar “cookies” y/o “web beacons” o
                bien confirmar si aceptan o no las mismas.
              </p>

              <p>
                <strong>
                  DATOS PERSONALES SENSIBLES.
                </strong>
              </p>

              <p>
                De conformidad con la legislación mexicana vigente, se conocen
                como “Datos Personales Sensibles” a “aquellos que puedan revelar
                aspectos como origen racial o étnico, estado de salud presente y
                futuro, información genética, creencias religiosas, filosóficas
                y/o morales, afiliación sindical, opiniones políticas,
                preferencia sexual”.
              </p>

              <p>
                En caso de que Fundación llegue a requerir datos personales
                sensibles de los usuarios, estos deberán proporcionar su
                consentimiento expreso.
              </p>

              <p>
                La fecha del presente aviso es el 25 de mayo de 2020.
              </p>

              <p>
                ************************
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
