import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { AnimationBuilder, AnimationPlayer} from '@angular/animations';
import { Animations } from './animations-types';

@Directive({
  selector: '[animateTag]'
})
export class AnimateTagDirective {
  
  // Helper that set enviroment to play the animation
  player: AnimationPlayer | undefined;
  // Helper variable to set privately the name of the animation
  private selectedAnimation = '';
  
  // Indicates the animation name 
  @Input()
  set animateType(animateType: string) {
    if (animateType) {
      this.selectedAnimation = animateType;
    }
  }
  
  constructor( private builder: AnimationBuilder,
      private el:ElementRef ) { }

  
  // Listening for the event mouse over to animate
  @HostListener('mouseover', ['$event'])
  onOver(event: MouseEvent): void {
      this.animate();
  }
  
  // Trigger the animation previously searched on the local library
  animate(): void{
    if (this.selectedAnimation ) {
        if (this.player) {
          this.player.destroy();
        }
        if (Animations[this.selectedAnimation]) {
          const metadata = Animations[this.selectedAnimation];
      
          const factory = this.builder.build(metadata);
          const player = factory.create(this.el.nativeElement);
          player.play();
        }
    }
  }//animate
}
