<!-- Modal -->
<div class="container modal-landing">
    <div class="row dialog__content">
        <div class="col-12 p-0 col-header">
            <button class="btn btn-close" (click)="close()">X</button>
        </div>
        <div class="col-12 col-body">
            <div class="row">
                <div class="col-12 col-md-7 p-5 col-info">
                    <img class="img-fluid" src="../../../../assets/img/landing/creactiva-color.png" alt="">
                    <button class="btn btn-blue mt-5 mb-5" (click)="irSitio()">¡VER LAS CÁPSULAS!</button>
                    <p>Comprometidos con la educación en México, Cuantrix de Fundación Televisa, en alianza con la empresa líder en papelería, 
                        BIC, nos invitan a descubrir nuevas formas de seguir aprendiendo y demuestran que la ciencia puede ir de la mano con 
                        la imaginación y la creatividad de una manera divertida.</p>
                    <p>La alianza lanza una serie de cápsulas donde podemos ver paso a paso como utilizar materiales que normalmente tenemos en 
                        casa para crear divertidísimos proyectos y aprender en el camino.</p>
                    <p>¡Te invitamos a que las conozcas todas y dejes volar tu imaginación!</p>
                </div>
                <div class="col-12 col-md-5 pb-4">
                    <img src="../../../../assets/img/landing/creactiva-back.png" alt="">
                </div>

            </div>
        </div>
    </div>
</div>