import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  // Globals urls for http requests
  globals = Globals;
  // Http urls excent to send token Authorization on headers
  tokenHttpUrlExcludes = [
    `${this.globals.users.pathUsers}${this.globals.users.login}`,
    `${this.globals.teachers.pathTeachers}${this.globals.teachers.create}`,
    `${this.globals.teachers.pathTeachers}${this.globals.teachers.validateEmail}`
  ];

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.token && !this.tokenHttpUrlExcludes.some( e => request.url.includes(e))  ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Token ${currentUser.token}`
        }
      });
    }
    return next.handle(request);
  }
}
