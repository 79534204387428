import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalCompuCuantrixComponent } from '../../components/landing/modal-compu-cuantrix/modal-compu-cuantrix.component';
import { ModalCuantrixComponent } from '../../components/landing/modal-cuantrix/modal-cuantrix.component';
import { ModalRetocuantrixComponent } from '../../components/landing/modal-retocuantrix/modal-retocuantrix.component';
import { ModalTechnolochicasComponent } from '../../components/landing/modal-technolochicas/modal-technolochicas.component';
import { ModalTelecuantrixComponent } from '../../components/landing/modal-telecuantrix/modal-telecuantrix.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  carousel = [
    { title: 'Programación', subtitle: 'fácil y divertida', description: 'Cuantrix es un programa de Fundación Televisa que busca generar equidad de oportunidades a través de las Ciencias de la Computación para que cada año en México un millón de niñas, niños y jóvenes aprendan a programar.', banner: '../../../assets/img/landing/banner-cuantrix.png', logo: '../../../assets/img/landing/logo-ccuantrix.png' },
    { title: 'Programación', subtitle: 'fácil y divertida', description: 'Brinda contenidos para la capacitación y aprendizaje de herramientas digitales, enfocados a adultos que buscan potenciar sus habilidades y conocimientos, llevándolos a su vida personal y laboral para generar mejores oportunidades.', banner: '../../../assets/img/landing/banner-ccuantrix.png', logo: '../../../assets/img/landing/logo-ccuantrix.png' },
    { title: 'Programación', subtitle: 'fácil y divertida', description: 'Es una competencia nacional que impulsa la creatividad y el talento de los niños y niñas de México, donde demuestren sus habilidades en programación mientras se divierten y aprenden juntos, con la oportunidad de obtener reconocimientos donados por Fundación Televisa y nuestros aliados.', banner: '../../../assets/img/landing/banner-rcuantrix.png', logo: '../../../assets/img/landing/logo-rcuantrix.png' },
    { title: 'Programación', subtitle: 'fácil y divertida', description: 'Un programa de la Fundación Televisa que tiene como objetivo inspirar y apoyar a niñas y mujeres jóvenes para estudiar una carrera relacionada con STEM (ciencia, tecnología, ingeniería y matemáticas).', banner: '../../../assets/img/landing/banner-tecnolochicas.png', logo: '../../../assets/img/landing/logo-tecnolochicas.png' }
  ];
  transitioning = false;
  page = 0;
  banner: any;
  timeSlide = 8000;
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    if(window.innerWidth < 767) {
      this.timeSlide = 15000;
    }
    this.resumeTransition();
  }
  /**
   * Function to redirect to external link
   */
  onAllVideos(): void{
    window.open('https://youtube.com/channel/UC16mAH7m4bURJKtgMW-VbzA', "location=yes");
  }

  beginTransition(): void{
    this.transitioning = true;
    setTimeout(()=> {
      this.changeBanner();
    }, 1000);
  }

  changeBanner(): void{
    if(this.page < (this.carousel.length - 1)) {
      this.page++;
    }
    else {
      this.page = 0;
    }
    this.transitioning = false;
  }

  stopTransition(): void{
    clearInterval(this.banner);
  }

  resumeTransition(): void{
    this.banner = setInterval(()=>{
      this.beginTransition();
    }, this.timeSlide);
    console.log('salio');
  }

  changePage(slide): void{
    this.stopTransition();
    this.page = slide;
    if(window.innerWidth < 767) {
      this.resumeTransition();
    }
  }

  /**
   * Function to display compu cuantrix dialog
   */
  onCompuCuantrix(): void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '508px';
    this.dialog.open(ModalCompuCuantrixComponent, dialogConfig);
  }
  /**
   * Function to display cuantrix dialog
   */
   onCuantrix(): void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
     dialogConfig.height = '460px';
    this.dialog.open(ModalCuantrixComponent, dialogConfig);
  }
  /**
   * Function to display tele cuantrix dialog
   */
   /* onTeleCuantrix(): void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
     dialogConfig.height = '579px';
    this.dialog.open(ModalTelecuantrixComponent, dialogConfig);
  } */
  /**
   * Function to display technolochicas dialog
   */
   onTechnolochicas(): void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
     dialogConfig.height = '658px';
    this.dialog.open(ModalTechnolochicasComponent, dialogConfig);
  }
  /**
   * Function to display reto cuantrix dialog
   */
   onRetoCuantrix(): void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '480px';
    this.dialog.open(ModalRetocuantrixComponent, dialogConfig);
  }
}
