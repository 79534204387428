export const environment = {
  production: false,
  apiUrl: 'https://dev-api-cuantrix.gilasw.com/api/v1/',
  firebase: {
    apiKey: 'AIzaSyCHHYNlxYRe_GiYFhnZ18-r11hFLmScjLA',
    authDomain: 'plataforma-cuantrix.firebaseapp.com',
    databaseURL: 'https://plataforma-cuantrix.firebaseio.com',
    projectId: 'plataforma-cuantrix',
    storageBucket: 'plataforma-cuantrix.appspot.com',
    messagingSenderId: '550522457436',
    appId: '1:550522457436:web:c3ff2416ad3a6b3ab1c599',
    measurementId: 'G-YPV242KX5L'
  }
};
