import { Injectable } from '@angular/core';
import { Globals } from 'src/app/classes/globals';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  globals = Globals;
  baseUrl = `${this.globals.urlCuantrixBase}${this.globals.users.pathUsers}`;
  constructor(private httpClient: HttpClient) { }

  /**
   * Method to validate if the username is available or not.
   * @param username - username to validate.
   */
  async validateUsername(username: string): Promise<object> {
    return await this.httpClient.post<any>(`${this.baseUrl}${this.globals.users.validate}`, {username}).toPromise();
  }

  /**
   * Method to validate if the email is available or not.
   * @param email - email to validate.
   */
  async validateEmail(email: string) {
    return await this.httpClient.post<any>(`${this.baseUrl}${this.globals.users.validateEmail}`, {'email': email}).toPromise();
  }
  async dataContact(account){
    const url = `${this.baseUrl}${this.globals.users.dataContact}`;
    return await this.httpClient.post(url, account).toPromise();

  }
}