<div class="container-fluid newCode_dialog">
    <div class="row">
        <div class="col-12 newCode__box margin--top">
            <div class="row newCode__header">
                <div class="col-12 col-md-6 text-center text-md-left mt-2">
                    <span class="newCode__title"> Nueva clave </span>
                </div>
                <div class="col-12 col-md-6 text-center text-md-right mt-2">
                    <img src="../../../../assets/img/logo2.png" alt="Cuantrix logo" width="160px">
                </div>
            </div>
            <div class="row newCode__content py-4">
                <div class="col-12 text-center pt-2">
                    <span class="newCode__message"> ¡Listo! se ha generado una nueva clave: </span>
                </div>
            </div>
            <div class="row recover__body" [formGroup]="studentCodeForm">
                <div class="col-12">
                    <div class="form-row mt-2 align-items-center justify-content-center">
                        <div class="col-auto mb-2 mb-md-0">
                            <input #input1 type="text" name=""
                                class="form-control input__code" maxlength="1" 
                                formControlName="firstNumber" #firstNumber >
                        </div>
                        <div class="col-auto mb-2 mb-md-0">
                            <input #input2  type="text" name=""
                                class="form-control input__code" maxlength="1" 
                                formControlName="secondNumber"  #secondNumber >
                        </div>
                        <div class="col-auto d-md-block d-none"><span class="recover__dash">-</span></div>
                        <div class="col-auto mb-2 mb-md-0">
                            <input #input3 type="text" name=""
                                class="form-control input__code" maxlength="1" 
                                formControlName="thirdNumber">
                        </div>
                        <div class="col-auto mb-2 mb-md-0">
                            <input #input4 type="text" name=""
                                class="form-control input__code" maxlength="1" 
                                formControlName="fourthNumber" >
                        </div>
                        <div class="col-auto d-md-block d-none"><span class="recover__dash">-</span></div>
                        <div class="col-auto mb-2 mb-md-0">
                            <input #input5 type="text" name=""
                                class="form-control input__code" maxlength="1"
                                formControlName="fifthNumber" >
                        </div>
                        <div class="col-auto mb-2 mb-md-0">
                            <input #input6 type="text" name=""
                                class="form-control input__code" maxlength="1" formControlName="sixthNumber">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row newCode__content py-5">
                <div class="col-12 col-md-12 text-center ">
                    <button class="btn btn__next" (click)="closeDialog()" animateTag animateType="bounce"> Aceptar </button>
                </div>
            </div>
        </div>
    </div>
</div>