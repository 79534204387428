<div class="container-fluid confirm_dialog">
    <div class="row">
        <div class="col-12 confirm__box margin--top">
            <div class="row confirm__header">
                <div class="col-12 col-md-6 text-center text-md-left mt-2">
                    <span class="confirm__title"> {{titleText}} </span>
                </div>
                <div class="col-12 col-md-6 text-center text-md-right mt-2">
                    <img src="../../../../assets/img/logo2.png" alt="Cuantrix logo" width="160px">
                </div>
            </div>
            <div class="row confirm__content py-4">
                <div class="col-12 text-center pt-2">
                    <span class="confirm__message"> {{msgText}} </span>
                </div>
            </div>
            <div class="row confirm__content justify-content-center pb-5">
                <div class="col-12 col-md-12 text-center my-2 ">
                    <button class="btn btn__cancel my-1  mr-md-4" (click)="setConfirm(false)"> {{btnCancelText}} </button>
                    <button class="btn btn__next" (click)="setConfirm(true)"> {{btnOkText}} </button>
                </div>
            </div>
        </div>
    </div>
</div>
