<div class="container dialog-informacion">
    <div class="row dialog__header">
        <div class="col-12 d-flex align-items-start justify-content-center">
            <p class="title pt-2 pb-3">
                {{titleText}}
            </p>
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="video-container">
          <iframe height="300px" id="video" [src]="videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="row dialog__content pt-5">
        <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12 offset-md-3 offset-xl-3 offset-lg-3 text-center">
            <a (click)="close()" class="btn btn-continuar">Continuar</a>
        </div>
    </div>
</div>
