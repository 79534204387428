import { animation, style, animate, keyframes, } from '@angular/animations';

export const Animations: any = {};
export const DEFAULT_TIMING = 0.7;

Animations['bounce'] = animation(
    [
        style({ transform: 'translate3d(0, 0, 0)' }),
        animate(
        '{{ timing }}s {{ delay }}s',
        keyframes([
            style({ transform: 'translate3d(0, 0, 0)', offset: 0.2 }),
            style({ transform: 'translate3d(0, -10px, 0)', offset: 0.4 }),
            style({ transform: 'translate3d(0, 0, 0)', offset: 0.53 }),
        ])
        ),
    ],
    { params: { timing: DEFAULT_TIMING, delay: 0 } }
);

Animations['pulse'] = animation(
    animate(
        '{{ timing }}s {{ delay }}s',
        keyframes([
        style({ transform: 'scale3d(1, 1, 1)' }),
        style({ transform: 'scale3d({{ scale }}, {{ scale }}, {{ scale }})' }),
        style({ transform: 'scale3d(1, 1, 1)' }),
        ])
    ),
    { params: { scale: 1.25, timing: DEFAULT_TIMING, delay: 0 } }
);