import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-telecuantrix',
  templateUrl: './modal-telecuantrix.component.html',
  styleUrls: ['./modal-telecuantrix.component.scss']
})
export class ModalTelecuantrixComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModalTelecuantrixComponent>) { }

  ngOnInit(): void {
  }

  /**
   * Close dialog with lesson video
   */
 close() {
  this.dialogRef.close();
  }
  
  /**
   * Funcion para redireccionar al sitio
   */
  irSitio(): void{
    window.open('https://youtube.com/playlist?list=PL3tpAgvwxDYeybnK5UUWaK1smPWfJEc5M', "location=yes");
  }

}
