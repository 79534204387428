<div class="parent-gate" [formGroup]="gateForm">
    <div class="container">
        <div class="row" (keyup.enter)="onSubmit()">
            <div class="col-12 gate__box margin--top">
                <div class="row gate__header">
                    <div class="col-12 text-center pt-5 pb-4">
                        <span class="gate__title">Bienvenido a</span>
                        <img src="../../../../assets/img/logo.png" alt="Cuantrix logo" width="220px">
                    </div>
                </div>
                <div class="row gate__content">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 pt-4">
                                <p class="content__title mt-5">Autenticación</p>
                                <p class="content__subtitle mt-5 mb-5">
                                    Favor de escribir la respuesta de la siguiente operación:
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-3">
                            <div class="col-auto mb-2 mb-md-0 symbol__container ">
                                <span class="number">{{firstNumber}}</span>
                            </div>
                            <div class="col-auto px-0 px-md-3 mb-2 mb-md-0 symbol__container ">
                                <span class="symbol">x</span>
                            </div>
                            <div class="col-auto mb-2 mb-md-0 symbol__container ">
                                <span class="number">{{secondNumber}}</span>
                            </div>
                            <div class="col-auto px-0 px-md-3 mb-2 mb-md-0 symbol__container ">
                                <span class="symbol">+</span>
                            </div>
                            <div class="col-auto mb-2 mb-md-0 symbol__container ">
                                <span class="number">{{thirdNumber}}</span>
                            </div>
                            <div class="col-auto px-0 px-md-3 mb-2 mb-md-0 symbol__container ">
                                <span class="symbol">=</span>
                            </div>
                            <div class="col-md-3 col-12 mt-4 mt-md-0 mb-2 mb-md-0 d-flex justify-content-center">
                                <input (keydown)="onlyNumber($event)" type="text" class="form-control input__code wider" maxlength="4" formControlName="answer" [ngClass]="{'input__code--invalid': correctAnswerValidator()}">
                            </div>
                        </div>

                        <div class="row no-gutters mb-4">
                            <div class="offset-2 col-4">
                                <hr class="hr--gray">
                            </div>
                            <div class="col-4">
                                <hr class="hr--blue" [ngClass]="{'hr--red': correctAnswerValidator()}">
                            </div>
                            <div *ngIf="correctAnswerValidator()" class="errors pt-1 col-12">
                                <span> La respuesta es incorrecta, inténtalo nuevamente.</span>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-5">
                            <div class="col-md-2 col-8">
                                <button (click)="onSubmit()" class="btn btn__next w-100" animateTag animateType="bounce">
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>