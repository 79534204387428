<!-- Modal -->
<div class="container modal-landing">
    <div class="row dialog__content">
        <div class="col-12 p-0 col-header">
            <button class="btn btn-close" (click)="close()">X</button>
        </div>
        <div class="col-12 col-body">
            <div class="row">
                <div class="col-12 col-md-7 p-5 col-info">
                    <img class="img-fluid" src="../../../../assets/img/landing/logo-tecnolochicas-color.png" alt="">
                    <button class="btn btn-blue mt-5 mb-5" (click)="irSitio()">IR AL SITIO</button>
                    <p>TECNOLOchicas es un programa de la Fundación Televisa que tiene como objetivo inspirar y apoyar a niñas y mujeres jóvenes para estudiar una carrera relacionada con STEM (ciencia, tecnología, ingeniería y matemáticas).</p>
                    <p>
                        Buscamos crear una comunidad de mujeres jóvenes y niñas de secundaria interesadas en áreas STEM que les genere un sistema de apoyo.</p>
                </div>
                <div class="col-12 col-md-5 col-image pb-4">
                    <img src="../../../../assets/img/landing/tecnolochicas-modal.png" alt="">
                </div>

            </div>
        </div>
    </div>
</div>