import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-modal-compu-cuantrix',
  templateUrl: './modal-compu-cuantrix.component.html',
  styleUrls: ['./modal-compu-cuantrix.component.scss']
})
export class ModalCompuCuantrixComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModalCompuCuantrixComponent>) { }

  ngOnInit(): void {
  }
  /**
   * Close dialog with lesson video
   */
  close() {
  this.dialogRef.close();
  }

  irSitio(): void{
    window.open('https://compucuantrix.mx/', "location=yes");
  }
}
