import { Role } from '../_models/Role';

// Class to implement attributes of User
export class User {
    id: string;
    username: string;
    role: Role;
    token: string;
    constructor(data: any) {
        this.id = data.content.user.id;
        this.username = data.content.user.username;
        this.role = data.content.user_type;
        this.token = data.content.token;
    }
}
