import { Component, OnInit, Inject  } from '@angular/core';
import { AlumnoService } from '../../services/alumno/alumno.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nueva-clave',
  templateUrl: './nueva-clave.component.html',
  styleUrls: ['./nueva-clave.component.scss']
})
export class NuevaClaveComponent implements OnInit {
  // Stdent Id to change code
  studentId;
  // Property to identify the student code form
  studentCodeForm: FormGroup;
  // Used for refresh code on parent page
  refreshCode: boolean;
  // New code generated
  newCode: string;
  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<NuevaClaveComponent>, private _alumnoService: AlumnoService,
    private formBuilder: FormBuilder,) {
    this.studentId = data.id;
    this.refreshCode = false;
    this.newCode = '';
    this.studentCodeForm = this.formBuilder.group({
      firstNumber: [{value: '', disabled: true}],
      secondNumber: [{value: '', disabled: true}],
      thirdNumber: [{value: '', disabled: true}],
      fourthNumber: [{value: '', disabled: true}],
      fifthNumber: [{value: '', disabled: true}],
      sixthNumber: [{value: '', disabled: true}]
    });
    this.changeStudentCode();

  }

  ngOnInit(): void {
  }

  // Http request to assign and get new student code
  changeStudentCode(): void {
    this._alumnoService.changeStudentCode(this.studentId).then(data => {
      if( data['error'] ) {
        this.refreshCode = false;
        Swal.fire({
          title: '¡Algo salio mal!',
          text:  data['message'],
          icon: 'error',
          customClass: {
            title: 'title',
            confirmButton: 'btn-ok'
          },
          showConfirmButton: true,
        });
      }else {
        this.setNewCodeForm(data['content']['results']['code']);
      }
    }).catch(error => {
      this.refreshCode = false;
      Swal.fire({
        title: '¡Algo salio mal!',
        text:  'Ha ocurrido un error. ⚠️',
        icon: 'error',
        customClass: {
          title: 'title',
          confirmButton: 'btn-ok'
        },
        showConfirmButton: true,
      });
    });
  }

  /**
  * Set values on dialog fields with the new code assigned at student
  * @param code - Code assigned at student
  */ 
  setNewCodeForm(code: string): void {
    this.newCode = code;
    this.studentCodeForm.get('firstNumber').setValue(code.split('')[0]);
    this.studentCodeForm.get('secondNumber').setValue(code.split('')[1]);
    this.studentCodeForm.get('thirdNumber').setValue(code.split('')[2]);
    this.studentCodeForm.get('fourthNumber').setValue(code.split('')[3]);
    this.studentCodeForm.get('fifthNumber').setValue(code.split('')[4]);
    this.studentCodeForm.get('sixthNumber').setValue(code.split('')[5]);
    this.refreshCode = true;
  }

  // Close dialog and send the new code to parent page
  closeDialog(): void {
    this.dialogRef.close( { refreshCode: this.refreshCode, newCode: this.newCode } );
  }

}
