<div class="home">
    <section class="container-fluid home__container d-flex flex-column justify-content-md-center">
        <app-menu></app-menu>
        <div class="row home__title">
            <div class="col-md-12 text-center">
                <h2 class="home__text mt-lg-0 mt-4"> <span class="home__letter--color">¡</span>Hola<span class="home__letter--color">!</span>, Bienvenid@ a</h2>
                <img class="logo" src="../../../assets/img/logo.png" alt="" height="100px">
            </div>
        </div>
        <div class="row pt-5">
            <div class="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 text-center div-description">
                <p>
                    Cuantrix apoya a los docentes, madres y padres de México para que niños y niñas de entre 6 y 18 años desarrollen habilidades de programación, en un ambiente virtual seguro y divertido.
                </p>
            </div>
        </div>
        <div class="row mt-4 justify-content-center">
            <div class="col-12 col-lg-5 mt-4 mt-lg-3 order-2 order-lg-1">
                <!-- <div class="col-lg-4 col-md-12 mt-lg-3 text-center text-lg-right"> -->
                <p class="text-center text-section">
                    Registra a tu hij@ o alumn@ para que pueda comenzar a disfrutar de Cuantrix.
                </p>
                <div class="row mt-lg-5">
                    <div class="col-12 d-flex justify-content-center mb-lg-4">
                        <button (click)="goToParentModule()" class="btn btn__padres" animateTag animateType="bounce" (mouseover)="hoverIn(2)">
                                    <img src="../../../assets/img/Inicio-padres.png" class="mr-3" alt="">
                                    Tutores</button>
                    </div>
                    <div class="col-md-12 d-md-block d-sm-block d-xs-block d-lg-none text-center mobile info-mobile mt-4">
                        <div class="align-middle align-items-center info-parents">
                            <div style="font-weight: bold; font-size: 2.3vw; text-align: justify; text-align-last: center;">
                                <p style="color: #ffffff;">SI ERES <span style="color: #C7FF00;">PADRE, MADRE O TUTOR</span> Y QUIERES QUE TU HIJO SEA PARTE DE <span style="color: #c1d101;"><img src="../../../assets/img/fondo_cuantrix.svg" alt="" style="height: 1.4rem;"></span>,
                                    EN ESTA SECCIÓN ENCONTRARÁS LOS PASOS A SEGUIR PARA PODER CREAR SU USUARIO Y BRINDARLES MEJORES OPORTUNIDADES PARA SU FUTURO.</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="modal==2" class="col-12 d-none d-lg-block text-center mb-4">
                        <div class="align-middle align-items-center info-paddings" style=" display: flex; background: url('../../../assets/img/fondo_padres_web.png'); background-repeat: no-repeat; background-size: contain; height: 220px; background-position: center; padding-left: 6.5vw; padding-right: 6.5vw;">
                            <div style="font-weight: bold; font-size: 1.05rem; text-align: justify; text-align-last: center;">
                                <p style="color: #ffffff;">SI ERES <span style="color: #C7FF00;">PADRE, MADRE O TUTOR</span> Y QUIERES QUE TU HIJO SEA PARTE DE <span style="color: #c1d101;"><img src="../../../assets/img/fondo_cuantrix.svg" alt="" style="height: 1.4rem;"></span>,
                                    EN ESTA SECCIÓN ENCONTRARÁS LOS PASOS A SEGUIR PARA PODER CREAR SU USUARIO Y BRINDARLES MEJORES OPORTUNIDADES PARA SU FUTURO.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <button routerLink="/maestro/login" class="btn btn__padres" animateTag animateType="bounce" (mouseover)="hoverIn(3)">
                                <img src="../../../assets/img/Inicio-maestros.png" class="mr-3" alt="">
                                Docentes</button>
                    </div>
                    <div class="col-md-12 d-md-block d-sm-block d-xs-block d-lg-none text-center mobile info-mobile mt-4">
                        <div class="align-middle align-items-center info-teachers">
                            <div style="font-weight: bold; font-size: 2.3vw; text-align: justify; text-align-last: center;">
                                <p style="color: #3d6eb5;"><span style="color: #82358c;">!MAESTRO¡</span> REGISTRA A TUS ALUMNOS PARA QUE PUEDAN ACCEDER A LAS ACTIVIDADES.</p>
                                <p style="color: #3d6eb5;">RECUERDA QUE PUEDES AGENDAR CLASES, VER LOS MANUALES Y DAR SEGUIMIENTO A LOS GRUPOS.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <div *ngIf="modal==3" class="col-12 d-none d-lg-block text-center mt-4">
                    <div class="align-middle align-items-center info-paddings" style=" display: flex; background: url('../../../assets/img/fondo_maestros_web.png'); background-repeat: no-repeat; background-size: contain; height: 220px; background-position: center; padding-left: 5.5vw; padding-right: 5.5vw;">
                        <div style="font-weight: bold; font-size: 1.05rem; text-align: justify; text-align-last: center;">
                            <p style="color: #3d6eb5;"><span style="color: #82358c;">!MAESTRO¡</span> REGISTRA A TUS ALUMNOS PARA QUE PUEDAN ACCEDER A LAS ACTIVIDADES.</p>
                            <p style="color: #3d6eb5;">RECUERDA QUE PUEDES AGENDAR CLASES, VER LOS MANUALES Y DAR SEGUIMIENTO A LOS GRUPOS.</p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12 col-lg-5 mt-4 mt-lg-3 order-1 order-lg-2">
                <p class="text-center text-section">
                    Si tu maestr@, padre, madre o tutor ya te registraron, ingresa aquí:
                </p>
                <div class="row mt-md-5">
                    <div class="col-12 d-flex justify-content-center">
                        <div>
                            <img src="../../../assets/img/Inicio-alumnos.png" class="rocket__img desktop" alt="" (mouseover)="hoverIn(1)">
                            <button animateTag animateType="bounce" routerLink="/alumno" class="btn btn__iniciar" (mouseover)="hoverIn(1)">
                              <img src="../../../assets/img/Inicio-alumnos.png" class="rocket__img mobile" alt="">
                                <span class="btn__iniciar--padding">Estudiantes</span>
                                <fa-icon [icon]="faChevron" class="chevron-right right"></fa-icon>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="modal==1" class="col-12 d-none d-lg-block text-center mt-5">
                        <div class="align-middle align-items-center info-paddings" style=" display: flex; background: url('../../../assets/img/fondo_ninos_web.png'); background-repeat: no-repeat; background-size: contain; height: 220px; background-position: center; padding-left: 6.5vw; padding-right: 6.5vw;">
                            <div style="font-weight: bold; font-size: 1.05rem; text-align: justify; text-align-last: center;">
                                <p style="color: #ffffff;">AQUI ES DONDE LOS <span style="color: #d8d807;">NIÑOS Y LAS NIÑAS </span>DEBEN ENTRAR PARA REALIZAR LOS EJERCICIOS, COMPLETAR LAS LECCIONES Y DESARROLLAR SUS NUEVAS HABILIDADES.</p>
                                <p style="color: #ffffff;">PARA PODER INGRESAR, SUS PADRES, TUTORES O DOCENTES DEBEN PRIMERO <span style="color: #d8d807;">REGISTRARLOS</span> USANDO LOS BOTONES CORRESPONDIENTES.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 d-md-block d-sm-block d-xs-block d-lg-none text-center mobile mt-4 info-mobile">
                        <div class="align-middle align-items-center div-info">
                            <div style="font-weight: bold; font-size: 2.3vw; text-align: justify; text-align-last: center;">
                                <p style="color: #ffffff;">AQUI ES DONDE LOS <span style="color: #d8d807;">NIÑOS Y LAS NIÑAS </span>DEBEN ENTRAR PARA REALIZAR LOS EJERCICIOS, COMPLETAR LAS LECCIONES Y DESARROLLAR SUS NUEVAS HABILIDADES.</p>
                                <p style="color: #ffffff;">PARA PODER INGRESAR, SUS PADRES, TUTORES O DOCENTES DEBEN PRIMERO <span style="color: #d8d807;">REGISTRARLOS</span> USANDO LOS BOTONES CORRESPONDIENTES.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="row mt-md-3 mt-0">
            <div class="col-lg-12 col-md-12 text-center"> -->
        <!-- <div>
                    <img src="../../../assets/img/Inicio-alumnos.png" class="rocket__img desktop" alt="" (mouseover)="hoverIn(1)" (mouseout)="hoverOut()">
                    <button animateTag animateType="bounce" routerLink="/alumno" class="btn btn__iniciar" (mouseover)="hoverIn(1)" (mouseout)="hoverOut()">
                      <img src="../../../assets/img/Inicio-alumnos.png" class="rocket__img mobile" alt="">
                        <span class="btn__iniciar--padding">Niños</span>
                        <fa-icon [icon]="faChevron" class="chevron-right right"></fa-icon>
                    </button>
                </div> -->
        <!-- </div> -->
        <!-- <div class="col-md-12 d-md-block d-sm-block d-xs-block d-lg-none text-center mobile mt-4">
                <div class="align-middle align-items-center" style=" display: flex; background: url('../../../assets/img/fondo_ninos_mobil.png'); background-repeat: no-repeat; background-size: contain; height: 220px; background-position: center; padding-left: 30%; padding-right: 30%;">
                    <div style="font-weight: bold; font-size: 2.3vw; text-align: justify; text-align-last: center;">
                        <p style="color: #ffffff;">AQUI ES DONDE LOS <span style="color: #d8d807;">NIÑOS Y LAS NIÑAS </span>DEBEN ENTRAR PARA REALIZAR LOS EJERCICIOS, COMPLETAR LAS LECCIONES Y DESARROLLAR SUS NUEVAS HABILIDADES.</p>
                        <p style="color: #ffffff;">PARA PODER INGRESAR, SUS PADRES, TUTORES O DOCENTES DEBEN PRIMERO <span style="color: #d8d807;">REGISTRARLOS</span> USANDO LOS BOTONES CORRESPONDIENTES.</p>
                    </div>
                </div>
            </div> -->
        <!-- </div> -->
        <!-- <div class="row mt-lg-5"> -->
        <!-- <div class="col-lg-4 col-md-12 mt-lg-3 text-center text-lg-right">
                <button (click)="goToParentModule()" class="btn btn__padres" animateTag animateType="bounce" (mouseover)="hoverIn(2)" (mouseout)="hoverOut()">
                    <img src="../../../assets/img/Inicio-padres.png" class="mr-3" alt="">
                    Padres</button>
            </div> -->
        <!-- <div class="col-md-12 d-md-block d-sm-block d-xs-block d-lg-none text-center mobile mt-4">
                <div class="align-middle align-items-center" style=" display: flex; background: url('../../../assets/img/fondo_padres_mobil.png'); background-repeat: no-repeat; background-size: contain; height: 220px; background-position: center; padding-left: 30%; padding-right: 30%;">
                    <div style="font-weight: bold; font-size: 2.3vw; text-align: justify; text-align-last: center;">
                        <p style="color: #ffffff;">SI ERES <span style="color: #C7FF00;">PADRE, MADRE O TUTOR</span> Y QUIERES QUE TU HIJO SEA PARTE DE <span style="color: #c1d101;"><img src="../../../assets/img/fondo_cuantrix.svg" alt="" style="height: 1.4rem;"></span>, EN ESTA
                            SECCIÓN ENCONTRARÁS LOS PASOS A SEGUIR PARA PODER CREAR SU USUARIO Y BRINDARLES MEJORES OPORTUNIDADES PARA SU FUTURO.</p>
                    </div>
                </div>
            </div> -->
        <!-- <div *ngIf="modal==0" class="col-lg-4 d-lg-block d-none" style="height: 220px;"></div> -->
        <!-- <div *ngIf="modal==3" class="col-lg-4 text-center">
                <div class="align-middle align-items-center" style=" display: flex; background: url('../../../assets/img/fondo_maestros_web.png'); background-repeat: no-repeat; background-size: contain; height: 220px; background-position: center; padding-left: 5.5vw; padding-right: 5.5vw;">
                    <div style="font-weight: bold; font-size: 1.05rem; text-align: justify; text-align-last: center;">
                        <p style="color: #3d6eb5;"><span style="color: #82358c;">!MAESTRO¡</span> REGISTRA A TUS ALUMNOS PARA QUE PUEDAN ACCEDER A LAS ACTIVIDADES.</p>
                        <p style="color: #3d6eb5;">RECUERDA QUE PUEDES AGENDAR CLASES, VER LOS MANUALES Y DAR SEGUIMIENTO A LOS GRUPOS.</p>
                    </div>
                </div>
            </div>
            <div *ngIf="modal==2" class="col-lg-4 text-center">
                <div class="align-middle align-items-center" style=" display: flex; background: url('../../../assets/img/fondo_padres_web.png'); background-repeat: no-repeat; background-size: contain; height: 220px; background-position: center; padding-left: 5.5vw; padding-right: 5.5vw;">
                    <div style="font-weight: bold; font-size: 1.05rem; text-align: justify; text-align-last: center;">
                        <p style="color: #ffffff;">SI ERES <span style="color: #C7FF00;">PADRE, MADRE O TUTOR</span> Y QUIERES QUE TU HIJO SEA PARTE DE <span style="color: #c1d101;"><img src="../../../assets/img/fondo_cuantrix.svg" alt="" style="height: 1.4rem;"></span>, EN ESTA
                            SECCIÓN ENCONTRARÁS LOS PASOS A SEGUIR PARA PODER CREAR SU USUARIO Y BRINDARLES MEJORES OPORTUNIDADES PARA SU FUTURO.</p>
                    </div>
                </div>
            </div> -->
        <!-- <div *ngIf="modal==1" class="col-lg-4 text-center">
                <div class="align-middle align-items-center" style=" display: flex; background: url('../../../assets/img/fondo_ninos_web.png'); background-repeat: no-repeat; background-size: contain; height: 220px; background-position: center; padding-left: 5.5vw; padding-right: 5.5vw;">
                    <div style="font-weight: bold; font-size: 1.05rem; text-align: justify; text-align-last: center;">
                        <p style="color: #ffffff;">AQUI ES DONDE LOS <span style="color: #d8d807;">NIÑOS Y LAS NIÑAS </span>DEBEN ENTRAR PARA REALIZAR LOS EJERCICIOS, COMPLETAR LAS LECCIONES Y DESARROLLAR SUS NUEVAS HABILIDADES.</p>
                        <p style="color: #ffffff;">PARA PODER INGRESAR, SUS PADRES, TUTORES O DOCENTES DEBEN PRIMERO <span style="color: #d8d807;">REGISTRARLOS</span> USANDO LOS BOTONES CORRESPONDIENTES.</p>
                    </div>
                </div>
            </div> -->
        <!-- <div class="col-lg-4 col-md-12 mt-lg-3 text-center text-lg-left">
                <button routerLink="/maestro/login" class="btn btn__padres" animateTag animateType="bounce" (mouseover)="hoverIn(3)" (mouseout)="hoverOut()">
                    <img src="../../../assets/img/Inicio-maestros.png" class="mr-3" alt="">
                    Maestros</button>
            </div> -->
        <!-- <div class="col-md-12 d-md-block d-sm-block d-xs-block d-lg-none text-center mobile mt-4">
                <div class="align-middle align-items-center" style=" display: flex; background: url('../../../assets/img/fondo_maestros_mobil.png'); background-repeat: no-repeat; background-size: contain; height: 220px; background-position: center; padding-left: 30%; padding-right: 30%;">
                    <div style="font-weight: bold; font-size: 2.3vw; text-align: justify; text-align-last: center;">
                        <p style="color: #3d6eb5;"><span style="color: #82358c;">!MAESTRO¡</span> REGISTRA A TUS ALUMNOS PARA QUE PUEDAN ACCEDER A LAS ACTIVIDADES.</p>
                        <p style="color: #3d6eb5;">RECUERDA QUE PUEDES AGENDAR CLASES, VER LOS MANUALES Y DAR SEGUIMIENTO A LOS GRUPOS.</p>
                    </div>
                </div>
            </div> -->
        <!-- </div> -->
        <div class="row row-h-50"></div>
        <div class="row home__link pb-4">
            <div class="col-12">
                <p class="mt-5">
                    Consulta nuestro
                    <a target="_blank" [routerLink]="[ '/aviso-privacidad']">aviso de privacidad</a>
                </p>
            </div>
        </div>
    </section>
</div>