import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParentGateComponent } from '../pages/maestros/parent-gate/parent-gate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoTabDirective } from '../directives/autotab.directive';
import { AnimateTagDirective } from '../directives/animate-tag/animate-tag.directive';
import { AvisoPrivacidadComponent } from '../pages/maestros/configuracion/aviso-privacidad/aviso-privacidad.component';

@NgModule({
  declarations: [
    ParentGateComponent,
    AvisoPrivacidadComponent,
    AutoTabDirective,
    AnimateTagDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ParentGateComponent,
    AvisoPrivacidadComponent,
    AutoTabDirective,
    AnimateTagDirective
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
