import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-parent-gate',
  templateUrl: './parent-gate.component.html',
  styleUrls: ['./parent-gate.component.scss']
})
export class ParentGateComponent implements OnInit {
  // Property to identify if the user is a teacher or a parent
  userType: string;
  // Property to identify the register form
  gateForm: FormGroup;
  // Property to identify if the form has been already submitted
  submitted = false;
  // Properties to identify the correct answer
  correctAnswer: number;
  firstNumber: number;
  secondNumber: number;
  thirdNumber: number;

  constructor(private router: Router, private fb: FormBuilder, private authenticationService: AuthenticationService) {
    this.setOperation();
  }

  ngOnInit(): void {
    this.getType();

    if (this.userType === 'maestro') {
      // redirect to dashboard if the user is a teacher
      this.authenticationService.isATeacher();
    }

    this.gateForm = this.fb.group({
      answer: ['', Validators.required]
    });
  }

  // method to retrieve the user type by the url
  getType = (): string => this.userType = this.router.url.replace('/', '');

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.gateForm.controls;
  }

  // Validator to identify if the entered value matchs with the correct answer and the form has been submitted
  correctAnswerValidator = (): boolean => (this.formFields.answer.value != this.correctAnswer) && this.submitted;

  // method to handle the submit event
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.gateForm.invalid || this.formFields.answer.value != this.correctAnswer) {
      return;
    }

    if (this.userType === 'maestro') {
      this.router.navigateByUrl(`/${this.userType}/login`);
    } else {
      this.router.navigateByUrl(`/${this.userType}/que-es-cuantrix`);
    }
  }

  /**
   * Method to prevent invalid key from being entered in input
   * @param e - Keydown event
   */
  onlyNumber(e: any): boolean {
    // 95, < 106 corresponds to Numpad 0 through 9
    // 47, < 58 corresponds to 0 through 9 on the Number Row; and 8 is Backspace/ 9 is Tab.
    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 37 || e.keyCode == 39)) {
      return false;
    }
  }

  // Set the random values of operation
  setOperation() {
    this.firstNumber = Math.floor((Math.random() * 299) + 1);
    this.secondNumber = Math.floor((Math.random() * 9) + 1);
    this.thirdNumber = Math.floor((Math.random() * 99) + 0);
    this.correctAnswer = (this.firstNumber * this.secondNumber) + this.thirdNumber;
  }

}
