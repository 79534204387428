import { Component, OnInit } from '@angular/core';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';
import { UsersService } from 'src/app/services/users/users.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {
  faArrow = faPaperPlane;
  // Property to identify the contact form
  contactForm: FormGroup;
  // Property to identify if the form has been already submitted
  submitted = false;
  snackbarService: any;
  constructor(private router: Router  ,private fb: FormBuilder,private userService: UsersService) {
    this.crearFormulario();
   }

  ngOnInit(): void {
    
  }
  // Convenience getter for easy access to form fields
  get formFields() {
    return this.contactForm.controls;
  }
  crearFormulario(): void {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      teacherOrTutor: [null, Validators.required],
      email: ['',[Validators.required, Validators.pattern('[A-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      message: ['', Validators.required]
    });
  }

  getAccount(): object {
    return {
      name: this.formFields.name.value,
      teacherOrTutor: this.formFields.teacherOrTutor.value,
      email: this.formFields.email.value,
      message: this.formFields.message.value,
    };
  }

  onSubmit(){
    this.submitted = true;
    // stop here if form is invalid
    console.log("this.contactForm", this.contactForm)
    if (this.contactForm.invalid ) {
      return;
    }
    let account = this.getAccount();
    this.userService.dataContact(account).then((resp:any) => {
      if(resp.success == true){
        console.log("success", resp.message)
        Swal.fire({
          title: '¡Gracias por escribirnos 🎉!',
          text: resp.message,
          icon: 'success',
          customClass: {
            title: 'title',
            confirmButton: 'btn-ok'
          },
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl("/inicio");
            this.contactForm.reset();
            
          }
        })
      }
    }).catch(err => {
      if (Object.keys(err.error).length > 1) {
        Swal.fire({
          title: '¡Algo salio mal!',
          text:  `${err.message}. ⚠️`,
          icon: 'error',
          customClass: {
            title: 'title',
            confirmButton: 'btn-ok'
          },
          showConfirmButton: true,
        });
      } else {
        let nameError = Object.keys(err.error)[0];
        Swal.fire({
          title: '¡Algo salio mal!',
          text:  `${err.error[nameError]}. ⚠️`,
          icon: 'error',
          customClass: {
            title: 'title',
            confirmButton: 'btn-ok'
          },
          showConfirmButton: true,
        });
        
        
      }
    }).finally(() => {
      this.submitted = false;
    });

  }
}
