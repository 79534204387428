import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-maestro-confirm',
  templateUrl: './maestro-confirm.component.html',
  styleUrls: ['./maestro-confirm.component.scss']
})
export class MaestroConfirmComponent implements OnInit {
  // Descision to confirmation or cancel action
  confirm: boolean;
  // Legend received to display title
  titleText: string;
  // Legend received to display message content
  msgText: string;
  // Legend received to display ok button text
  btnOkText: string;
  // Legend received to display cancel button text
  btnCancelText: string;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<MaestroConfirmComponent>) {
    this.titleText = data.msgTitle;
    this.msgText = data.msgContent;
    this.btnOkText = data.msgOkBtn;
    this.btnCancelText = data.msgCancelBtn;
  }

  ngOnInit(): void {
  }

  /**
  * Set value true or false, to confirm or not the answer dialog
  * @param {boolean} confirmValue - True: confirm - false: cancel
  */
  setConfirm(confirmValue: boolean): void {
    this.confirm = confirmValue;
    this.closeDialog();
  }

  // Close dialog and send id group and boolean (refreshStudentsList) to control the refresh group list
  closeDialog(): void {
    this.dialogRef.close( { confirm: this.confirm } );
  }
}
