import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-technolochicas',
  templateUrl: './modal-technolochicas.component.html',
  styleUrls: ['./modal-technolochicas.component.scss']
})
export class ModalTechnolochicasComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModalTechnolochicasComponent>) { }

  ngOnInit(): void {
  }
/**
   * Close dialog with lesson video
   */
 close() {
  this.dialogRef.close();
  }
  
  /**
   * Funcion para redireccionar al sitio
   */
  irSitio(): void{
    window.open('https://tecnolochicas.mx/', "location=yes");
  }
}
