import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyAdviceComponent } from './pages/privacy-advice/privacy-advice.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from './material.module';
import { AyudaComponent } from './pages/maestros/ayuda/ayuda.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MaestroConfirmComponent } from './dialogs/maestro-confirm/maestro-confirm.component';
import { NuevaClaveComponent } from './dialogs/nueva-clave/nueva-clave.component';
import { DatePipe } from '@angular/common';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from '../environments/environment';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as firebase from 'firebase';
import { LandingComponent } from './pages/landing/landing.component';
import { ModalCompuCuantrixComponent } from './components/landing/modal-compu-cuantrix/modal-compu-cuantrix.component';
import { ModalCuantrixComponent } from './components/landing/modal-cuantrix/modal-cuantrix.component';
import { ModalTelecuantrixComponent } from './components/landing/modal-telecuantrix/modal-telecuantrix.component';
import { ModalTechnolochicasComponent } from './components/landing/modal-technolochicas/modal-technolochicas.component';
import { ModalRetocuantrixComponent } from './components/landing/modal-retocuantrix/modal-retocuantrix.component';
import { InformacionComponent } from './dialogs/informacion/informacion.component';
import { MenuComponent } from './components/menu/menu.component';
import { QueEsCuantrixComponent } from './components/que-es-cuantrix/que-es-cuantrix.component';
import { ContactoComponent } from './pages/contacto/contacto.component';


if (environment.production === true) {
  firebase.initializeApp(environment.firebase);
}

//  modules to import only if the site is in PRODUCTION mode
let prodImports = [
  AngularFireModule.initializeApp(environment.firebase),
  AngularFireAnalyticsModule,
];

// if the environment is DEV clear production imports
if (environment.production !== true) {
  prodImports = [];
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AyudaComponent,
    PrivacyAdviceComponent,
    MaestroConfirmComponent,
    AyudaComponent,
    NuevaClaveComponent,
    PrivacyAdviceComponent,
    LandingComponent,
    ModalCompuCuantrixComponent,
    ModalCuantrixComponent,
    ModalTelecuantrixComponent,
    ModalTechnolochicasComponent,
    ModalRetocuantrixComponent,
    InformacionComponent,
    MenuComponent,
    QueEsCuantrixComponent,
    ContactoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    SharedModule,
    NgSelectModule,
    MatMomentDateModule,
    ...prodImports
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class AppModule {
}
