import { Injectable } from '@angular/core';
import { Globals } from '../../classes/globals';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Kid } from 'src/app/classes/kid/kid';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlumnoService {
  globals = Globals;
  baseUrl = `${this.globals.urlCuantrixBase}${this.globals.students.pathStudents}`;
  constructor(private httpClient: HttpClient) { }

  /**
   * Http request to add student on group
   * @param {object} request - Data of student
   * @param {number} groupId - Id of group
   */
  async createStudent(request: any) {
    let url = `${this.globals.urlCuantrixBase}${this.globals.students.pathStudents}`;
    return this.httpClient.post(url, request).toPromise();
  }

  // Returns an Promise
  async csvStudents(groupId, file): Promise<any> {
    const formData: FormData = new FormData();
    const url = `${this.baseUrl}${this.globals.students.csvstudents}`;
    formData.append('groupId', groupId);
    formData.append('csv', file);
    return await this.httpClient.post(url, formData).toPromise();
}
  /**
   * Get student info
   * @param studentId - Id of student to get info
   */
  async getStudentById(studentId: number) {
    let url = `${this.globals.urlCuantrixBase}${this.globals.students.pathStudents}${studentId}/`;
    return this.httpClient.get(url).toPromise();
  }

  /**
   * Method to register a kid from the kid app.
   * @param kid - Data of the kid
   */
  async register(kid: Kid): Promise<Kid> {
    return await this.httpClient.post<Kid>(`${this.baseUrl}${this.globals.students.register}`, kid).toPromise();
  }

  /**
   * Get student info by code
   * @param code - Code of student to get info
   */
  async getStudentByCode(code: string) {
    let url = `${this.globals.urlCuantrixBase}${this.globals.students.pathStudents}${this.globals.students.getByCode}?code=${code}`;
    return this.httpClient.get(url).toPromise();
  }

  /**
   * Http request to update student info, used for change of group
   * @param request - Data of student
   * @param studentId - Id of student
   */
  async updateStudent(request: any, studentId: number) {
    let url = `${this.globals.urlCuantrixBase}${this.globals.students.pathStudents}${studentId}/`;
    return this.httpClient.put(url, request).toPromise();
  }

  /**
   * Http request to change the student code
   * @param studentId - Id of student
   */
  async changeStudentCode(studentId: number) {
    let url = `${this.globals.urlCuantrixBase}${this.globals.students.pathStudents}${studentId}/generate_kid_code/`;
    return this.httpClient.get(url).toPromise();
  }


  /**
   * mark a lesson as completed
   * @param lesson - lesson id
   */
  async completeLesson(lesson: string) {
    const url = `${this.baseUrl}${this.globals.students.lessonDone}`;
    return await this.httpClient.post(url, { lesson }).toPromise();
  }

  /**
   * Get summary th days played and an object of played days
   * @param studentId - Student id
   * @param dateValues - Month and year
   */
  async calendarSummaryByStudentId(studentId: number, dateValues: object) {
    const params = new HttpParams().set('year', dateValues['year']).set('month', dateValues['month']);
    let url = `${this.baseUrl}${studentId}/sessions/`;
    return await this.httpClient.get(url, { params }).toPromise();
  }

  /**
   * Send recover password email
   * @param username - Username to which the email will be sent to recover password
   */
  async forgotPassword(username: any) {
    return await this.httpClient.post(`${this.baseUrl}${this.globals.students.forgotPassword}`, username).toPromise();
  }

  /**
   * Update password
   * @param data - Object with token, password and confirm_password for the update password
   */
  async resetPassword(data: any) {
    return await this.httpClient.post(`${this.baseUrl}${this.globals.students.resetPassword}`, data).toPromise();
  }

  /**
   * Reset a kid password by code
   * @param kid - Data of the kid including his code
   */
  async resetPasswordByCode(kid: Kid) {
    return await this.httpClient.post<Kid>(`${this.baseUrl}${this.globals.students.resetByCode}`, kid).toPromise();
  }

  /**
   * Get the answers of a survey by the student id
   * @param attempt - id of the attempt of the survey to be consulted
   * @param studentId - Id of student
   */
  async surveyAnswers(studentId: string, attempt: string) {
    const url = `${this.baseUrl}${studentId}${this.globals.students.survey}`;
    return this.httpClient.post(url, { attempt }).toPromise();
  }

}
