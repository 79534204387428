import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faHome, faBars } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  faHome = faHome;
  faBars = faBars;
  show:boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onRedirectHome(){
    this.router.navigateByUrl("/");
  }
}
