import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-modal-cuantrix',
  templateUrl: './modal-cuantrix.component.html',
  styleUrls: ['./modal-cuantrix.component.scss']
})
export class ModalCuantrixComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModalCuantrixComponent>) { }

  ngOnInit(): void {
  }
  /**
   * Close dialog with lesson video
   */
   close() {
    this.dialogRef.close();
    }
    
    /**
     * Funcion para redireccionar al sitio
     */
    irSitio(): void{
      window.open('/inicio', "location=yes");
    }
}
