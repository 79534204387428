<nav class="navbar">
    <div class="container-fluid justify-content-center">
        <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->
        <img src="../../../assets/img/landing/logo-cuantrix-blanco.png" alt="">
    </div>
</nav>
<!-- <div class="collapse" id="navbarToggleExternalContent">
    <div class="bg-dark p-4">
        <h5 class="text-white h4">Collapsed content</h5>
        <span class="text-muted">Toggleable via the navbar brand.</span>
    </div>
</div> -->
<div (mouseover)="stopTransition()" (mouseout)="resumeTransition()">
    <div class="container-fluid seccion-programacion" [ngClass]="{'seccion-programacion-transitioning': transitioning, 'seccion-reto': page == 2, 'seccion-tecnolochicas': page == 3}">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-7 col-info">
                    <div *ngIf="page==0">
                        <h1>{{carousel[page].title}}</h1>
                        <h1>{{carousel[page].subtitle}}</h1>
                    </div>
                    <div *ngIf="page>0">
                        <img [src]="carousel[page].logo" alt="" style="width: 100%;">
                    </div>
                    <p class="mt-5">{{carousel[page].description}}</p>
                </div>
                <div class="col-12 col-md-5 col-img">
                    <img class="img-fluid" [src]="carousel[page].banner" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center" style="position: relative; bottom: 30px; margin-bottom: -50px; height: 50px; width: 100%;">
        <div class="d-inline-flex align-items-center" style="height: 25px;">
            <div *ngFor="let slide of carousel; let i = index;" class="carousel-page ml-2" [ngClass]="{'carousel-current': page==i}" (click)="changePage(i)"></div>
        </div>
    </div>
</div>
<div class="container-fluid seccion-empresas">
    <div class="container">
        <div class="row">
            <div class="col col-img">
                <img class="img-fluid" src="../../../assets/img/landing/logo-microsoft-color.png" alt="">
            </div>
            <div class="col col-img">
                <img class="img-fluid" src="../../../assets/img/landing/logo-sura-color.png" alt="">
            </div>
            <div class="col col-img">
                <img class="img-fluid" src="../../../assets/img/landing/logo-accenture-color.png" alt="">
            </div>
            <div class="col col-img">
                <img class="img-fluid" src="../../../assets/img/landing/logo-izzi-color.png" alt="">
            </div>
            <div class="col col-img">
                <img class="img-fluid" src="../../../assets/img/landing/logo-futel-color.png" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid container-iniciativas">
    <div class="container pb-5">
        <div class="row mb-md-5">
            <div class="col-12 text-center col-titulo mt-5 mb-5">
                <h3>
                    CONOCE NUESTRAS DIFERENTES INICIATIVAS
                </h3>
            </div>
            <div class="col-12 col-md-6">
                <div class="row justify-content-center">
                    <div class="col-11 mb-5 mb-md-0 col-logos" (click)="onCuantrix()">
                        <img class="img-cuantrix" src="../../../assets/img/landing/logo-cuantrix-blanco.png" alt="" style="width: auto;">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row justify-content-center">
                    <div class="col-11 mb-5 mb-md-0 col-logos" (click)="onCompuCuantrix()">
                        <img src="../../../assets/img/landing/logo-compucuantrix-blanco.png" alt="" style="width: auto;">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="row justify-content-center">
                    <div class="col-11 mb-5 mb-md-0 col-logos" (click)="onRetoCuantrix()">
                        <img src="../../../assets/img/landing/logo-retocuantrix-blanco.png" alt="" style="width: auto;">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row justify-content-center">
                    <div class="col-11 mb-5 mb-md-0 col-logos" (click)="onTechnolochicas()">
                        <img class="img-technolochicas" src="../../../assets/img/landing/logo-tecnolochicas-blanco.png" alt="" style="width: auto;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid container-funcionan">
    <div class="container">
        <div class="row">
            <div class="col-12 col-titulo text-center mt-5 pt-5 mb-5">
                <h3>
                    ¿CÓMO FUNCIONAN NUESTRAS INICIATIVAS?
                </h3>
            </div>
            <div class="col-12 col-md-4">
                <div class="row justify-content-center">
                    <div class="col-11 col-cards mb-5 mb-md-0">
                        <div class="card">
                            <img src="../../../assets/img/landing/comunidad-icono.png" class="card-img-top img-fluid" alt="...">
                            <div class="card-body">
                                <h2 class="card-title">COMUNIDAD</h2>
                                <p class="card-text">Genera nuevos vínculos que te ayuden a desarrollar tus nuevas capacidades.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="row justify-content-center">
                    <div class="col-11 col-cards mb-5 mb-md-0">
                        <div class="card">
                            <img src="../../../assets/img/landing/comunicacion-icono.png" class="card-img-top img-fluid" alt="...">
                            <div class="card-body">
                                <h2 class="card-title">COMUNICACIÓN</h2>
                                <p class="card-text">Participa activamente en los temas relacionados a la tecnología y programación que te compartimos.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="row justify-content-center">
                    <div class="col-11 col-cards mb-5 mb-md-0">
                        <div class="card">
                            <img src="../../../assets/img/landing/oportunidades-icono.png" class="card-img-top img-fluid" alt="...">
                            <div class="card-body">
                                <h2 class="card-title">OPORTUNIDADES</h2>
                                <p class="card-text">Desarrolla nuevas capacidades en tecnología y programación para acceder a nuevas oportunidades en el futuro.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-videos">
            <div class="col-12 text-center col-title">
                <h3>
                    VIDEOS
                </h3>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid container-videos pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 mb-5">
                <div class="row">
                    <div class="col-12">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/qHAilBtZ3nE" frameborder="0"></iframe>
                        <h1>¿Por qué aprender a programar?</h1>
                        <p>Aprender programación es tu oportunidad para hacer algo creativo, útil y diferente</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-5">
                <div class="row">
                    <div class="col-12">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/2IH1VMg9bXI" frameborder="0"></iframe>
                        <h1>Conoce la experiencia Cuantrix</h1>
                        <p>Cuantrix es una plataforma digital que apoya a los maestros para que sus alumnos aprendan nociones básicas de programación simple y divertidamente.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-5">
                <div class="row">
                    <div class="col-12">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/IZdb-xqphm4" frameborder="0"></iframe>
                        <h1>Telecuantrix</h1>
                        <p>Una nueva forma de aprender a distancia sobre programación y ciencias de la computación</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-5">
                <div class="row">
                    <div class="col-12">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/G6OoX68RgdY" frameborder="0"></iframe>
                        <h1>Maestros Cuantrix enseñando pensamiento computacional </h1>
                        <p>Maestros CuantriX nos hablan de su experiencia impartiendo talleres de pensamiento computacional en las escuelas.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-button text-center">
                <button class="btn btn-blue" (click)="onAllVideos()">VER TODOS LOS VIDEOS</button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid container-footer">
    <div class="container pl-5 pl-md-0">
        <div class="row mb-5 mb-md-0">
            <div class="col-12 col-md-3">
                <h3>Otras plataformas</h3>
                <ul>
                    <li><a target="_blank" [routerLink]="[ '/inicio']">Cuantrix</a></li>
                    <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.fundaciontelevisa.compucuantrix&referrer=utm_source%3DLanding%2520pagefooter%26utm_medium%3DSeccion%2520CC%26utm_campaign%3DCuantrix">Compucuantrix</a></li>
                    <li><a target="_blank" href="https://tecnolochicas.mx/">TECNOLOchicas</a></li>
                    <li><a target="_blank" href="https://youtube.com/playlist?list=PL3tpAgvwxDYfzSYLdcTguxnq2ypxLa9Gg">Telecuantrix</a></li>
                    <li><a target="_blank" href="https://reto.cuantrix.mx/">Reto Cuantrix</a></li>
                </ul>
            </div>
            <div class="col-12 col-md-3">
                <h3>Aplicaciones</h3>
                <ul>
                    <li (click)="onCompuCuantrix()">Compucuantrix</li>
                </ul>
            </div>
            <div class="col-12 col-md-3">
                <h3>Ayuda Soporte</h3>
                <ul>
                    <li><a target="_blank" href="https://www.facebook.com/cuantrixmx">Facebook</a></li>
                    <li><a target="_blank" href="https://www.instagram.com/cuantrix/">Instagram</a></li>
                </ul>
            </div>
            <div class="col-12 col-md-3">
                <h3>Términos y Privacidad</h3>
                <ul>
                    <li><a target="_blank" [routerLink]="[ '/aviso-privacidad']">Aviso de privacidad</a></li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col col-img"><img class="img-fluid" src="../../../assets/img/landing/logo-microsoft-blanco.png" alt=""></div>
            <div class="col col-img"><img class="img-fluid" src="../../../assets/img/landing/logo-sura-blanco.png" alt=""></div>
            <div class="col col-img"><img class="img-fluid" src="../../../assets/img/landing/logo-accenture-blanco.png" alt=""></div>
            <div class="col col-img offset-2 offset-lg-0"><img class="img-fluid" src="../../../assets/img/landing/logo-izzi-blanco.png" alt=""></div>
            <div class="col col-img"><img class="img-fluid futel" src="../../../assets/img/landing/logo-futel-blanco.png" alt=""></div>
        </div>
    </div>
</div>